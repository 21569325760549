import { useEffect } from 'react';
import pic1 from "../../assets/Pic1.webp";
import pic2 from "../../assets/Pic2.webp";
import pic3 from "../../assets/Pic3.webp";
import pic4 from "../../assets/Pic4.webp";
import bg1 from '../../assets/BG1.webp';
import bg from '../../assets/BG.webp';
import inst from '../../assets/Instlogo.webp';

const ImagePreloader = ({ setImgsLoaded }) => {
  useEffect(() => {
    const images = [pic1, bg, pic2, bg1, pic3, pic4, inst];
    
    // Preload images
    const loadImage = (href) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = href;
        img.onload = () => resolve(href);
        img.onerror = () => reject(new Error(`Failed to load image: ${href}`));
      });
    };

    Promise.all(images.map(image => loadImage(image)))
      .then(() => setImgsLoaded(true))  // Signal that images are loaded
      .catch(err => console.error(err));
    
    // Preload images using <link> tags (for better browser support)
    images.forEach(href => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = href;
      document.head.appendChild(link);
    });

    // Cleanup on unmount
    return () => {
      const links = document.querySelectorAll('link[rel="preload"]');
      links.forEach(link => document.head.removeChild(link));
    };
  }, [setImgsLoaded]);

  return null;
};

export default ImagePreloader;

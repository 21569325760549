import React, { useState, useEffect } from "react"
import "./App.scss"
import { BrowserRouter } from "react-router-dom"
import { useRoutes } from "./routes/general.routes"
import "./style/normalize.css"
import "./style/reset.css"
import "./style/fonts.scss"
import { RecurlyProvider, Elements } from "@recurly/react-recurly"
import { Provider } from "react-redux"
import store from "./store/store"
import ImagePreloader from "../shared/lib/config/ImagePreloader"
import { analytics } from '../shared/lib/config/firebaseConfig';
import { logEvent } from "firebase/analytics";
import Facebook from "../shared/lib/utils/facebookTracking"

const publicRecurlyKey = process.env.REACT_APP_RECURLY_PUBLIC_KEY

function App() {
  const routes = useRoutes()
  const [imgsLoaded, setImgsLoaded] = useState(false)
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'session_start');
    }
  }, []);
  return (
    <div className="App">
      <ImagePreloader setImgsLoaded={setImgsLoaded} />
      <Provider store={store}>
        <Facebook/>
        <RecurlyProvider publicKey={publicRecurlyKey}>
          <Elements>
            <BrowserRouter>
              <div className="Main-Wrapper">
                {imgsLoaded ? routes : <div className="loading-screen"></div>}
              </div>
            </BrowserRouter>
          </Elements>
        </RecurlyProvider>
      </Provider>
    </div>
  )
}

export default App

import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import {
  OnBoardingNavigationPage,
  OnBoardingPage,
  UsernameEnterPage,
  AccountCreatingPage,
  PrivacyPolicy,
  TermsOfUse,
  GetPaidAccount,
} from "../../pages"
import ScrollToTop from "../../shared/lib/hooks/useScrollToTop"

export const useRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/main" exact element={<OnBoardingPage />} />
        <Route
          path="/onboarding"
          exact
          element={<OnBoardingNavigationPage />}
        />
        <Route path="/user-enter" exact element={<UsernameEnterPage />} />
        <Route path="/account-create" exact element={<AccountCreatingPage />} />
        <Route path="/terms-of-servise" exact element={<TermsOfUse />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/get-account" exact element={<GetPaidAccount />} />
        <Route path="/*" element={<Navigate replace to="/main" />} />
      </Routes>
    </>
  )
}

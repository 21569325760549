import { postCheckSignUpToken, postSignUpUser, getSignUpToken } from '../../../../features/Slices/Auth/thunks';
import { useDispatch } from 'react-redux';

const useAuthData = () => {
  const dispatch = useDispatch();


  const checkSignUpToken = (token) => {
    dispatch(postCheckSignUpToken({ token }));
  };



  const dispatchGetSignUpToken = (token) => {
    dispatch(getSignUpToken({ token }));
  };



  const signUp = async (email, password, token) => {
    await dispatch(postSignUpUser({ email, password, token }));
  };

  return { checkSignUpToken, signUp, dispatchGetSignUpToken };
};

export default useAuthData;
import React, { useEffect } from "react"
import { DefaultInput, DefaultButton } from "../../../../shared"
import { Icon } from "../../../../shared"
import "./accessToApp.scss"
import { useAccessForm } from "../../../../shared/lib/hooks/useAccessForm"
import useAuthData from "../../../../shared/lib/hooks/auth/auth.hook"
import GetAuthData from "../../../../shared/lib/hooks/auth/authSelector.hook"
import { useLocation } from "react-router-dom"

const AccessToApp = ({ token }) => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const emailFromQuery = queryParams.get("email")

  const {
    email,
    setEmail,
    password,
    showPassword,
    togglePasswordVisibility,
    handleEmailChange,
    handlePasswordChange,
    isValidEmail,
    isMissingAt,
    isMissingDot,
    isButtonDisabledEmailPassword,
    isPasswordShort,
    isPassworLong,
    isLoginError,
    setIsLoginError,
  } = useAccessForm()

  const { signUp } = useAuthData()
  const { error } = GetAuthData()

  useEffect(() => {
    if (emailFromQuery) {
      setEmail(emailFromQuery)
    }
  }, [emailFromQuery, setEmail])

  const handleSubmit = (event) => {
    event.preventDefault()
    signUp(email, password, token)
  }

  useEffect(() => {
    setIsLoginError(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <div className="access-app-block">
      <div>
        <DefaultInput
          placeholder="Enter your email"
          onChange={handleEmailChange}
          value={email}
          text="Email"
          type="email"
          isError={!isValidEmail && email.length > 0}
        />
      </div>
      {!isValidEmail && email.length > 0 && (
        <p className="title11-regular-outfit error-message">
          {isMissingAt
            ? 'Email must contain "@" symbol.'
            : isMissingDot
              ? 'Email must contain a dot after the "@" symbol.'
              : "Please enter a valid email address."}
        </p>
      )}
      <div className="acess-app-input">
        <DefaultInput
          placeholder="Enter your password"
          onChange={handlePasswordChange}
          value={password}
          text="Password"
          type={showPassword ? "text" : "password"}
          isError={isPasswordShort}
        />
        <span className="password-toggle" onClick={togglePasswordVisibility}>
          <Icon
            type={showPassword ? "openEye" : "closedEye"}
            alt={showPassword ? "openEye" : "closedEye"}
            width={24}
            height={24}
            strokeColor="#B0AECC"
            className={`eye-icon ${showPassword ? "eye-open" : "eye-closed"}`}
          />
        </span>
      </div>
      {isPasswordShort && password.length > 0 ? (
        <p className="title11-regular-outfit error-message">
          The password must be 6 characters long or more.
        </p>
      ) : isPassworLong ? (
        <p className="title11-regular-outfit error-message">
          The password must be less than 255 characters long.
        </p>
      ) : null}
      {isLoginError && (
        <p className="title11-regular-outfit error-message">{error}</p>
      )}
      <div className="acess-app-button">
        <DefaultButton
          text="sign up"
          onClick={handleSubmit}
          isDisabled={isButtonDisabledEmailPassword}
        />
      </div>
    </div>
  )
}

export default AccessToApp

import React from "react"
import "./onBoarding.scss"
import { OnBoadrdingUnder } from "../../../widgets"

const OnBoarding = () => {
  return (
    <div className="onboarding-wrapper">
      <OnBoadrdingUnder />
    </div>
  )
}

export default OnBoarding

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const IS_PROXY_CONNECTION = process.env.REACT_APP_IS_PROXY_CONNECTION === "true"

export const endpoints = {
  general: {
    auth: {
      post: {
        playerAuth: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/player`
          : `/api/auth/player`,
        checkSignUpToken: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/check-sign-up-eligibility`
          : `/api/auth/check-sign-up-eligibility`,
        logout: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/logout`
          : `/api/auth/logout`,
        signUp: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/sign-up`
          : `/api/auth/sign-up`,
        generateCustomToken: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/generate-custom-token`
          : `/api/auth/generate-custom-token`,
      },
      get: {
        getMe: !IS_PROXY_CONNECTION ? `${REACT_APP_BACKEND_URL}/api/auth/me` : `/api/auth/me`,
        customToken: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/custom-token`
          : `/api/auth/custom-token`,
        signUpToken: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/auth/sign-up-token`
          : `/api/auth/sign-up-token`,
      },
    },
    payment: {
      get: {
        plan: !IS_PROXY_CONNECTION ? `${REACT_APP_BACKEND_URL}/api/payment/plan` : `/api/payment/plan`,
        currency: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/payment/currency`
          : `/api/payment/currency`,
      },
      post: {
        subscribeWithCard: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/payment/subscribe-with-card`
          : `/api/payment/subscribe-with-card`,
        subscribeWithGooglePay: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/payment/subscribe-with-google-pay`
          : `/api/payment/subscribe-with-google-pay`,
        subscribeWithApplePay: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/payment/subscribe-with-apple-pay`
          : `/api/payment/subscribe-with-apple-pay`,
      },
    },
    instagram: {
      get: {
        instagramProfile: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/instagram/profile`
          : `/api/instagram/profile`,
        analyticProfile: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/instagram/profile-analytic`
          : `/api/instagram/profile-analytic`,
      },
    },
    user: {
      patch: {
        instagramUserByUsername: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/user/instagram-username`
          : `/api/user/instagram-username`,
      },
      get: {
        me: !IS_PROXY_CONNECTION
          ? `${REACT_APP_BACKEND_URL}/api/user/me`
          : `/api/user/me`,
      },
    },
  },
}

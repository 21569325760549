export function createQueryString(data) {
    let queryArray = []
  
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let value = data[key]
        let newKey = key
        if (value || typeof value === "boolean") {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              queryArray.push(
                `${newKey}=${encodeURIComponent(JSON.stringify(item))}`
              )
            })
          } else {
            queryArray.push(`${newKey}=${encodeURIComponent(value)}`)
          }
        }
      }
    }
  
    return queryArray.join("&")
  }
import React, { useEffect, useState } from "react"
import "./accountCreating.scss"
import { CreateAcc } from "../../../widgets"
import { useSearchParams } from 'react-router-dom';
import useAuthData from "../../../shared/lib/hooks/auth/auth.hook";

import GetAuthData from "../../../shared/lib/hooks/auth/authSelector.hook";
import { Loader } from "../../../shared";


const AccountCreating = () => {

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { checkSignUpToken, dispatchGetSignUpToken } = useAuthData();
  const { successSignInToken, loading, tokenHash, customToken, error } = GetAuthData();
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    if (token) {
      dispatchGetSignUpToken(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (tokenHash) {
      checkSignUpToken(tokenHash)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenHash])


  useEffect(() => {
    if (customToken) {
      setLoadingError(true)
      const clientUrl = process.env.REACT_APP_FULL_CLIENT;
      window.location.href = `${clientUrl}/login?token=${customToken}`;
    } else {
      console.error('Sign-up failed');
      setLoadingError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customToken])

  
  useEffect(() => {
    setLoadingError(false)
  }, [error])


  if (loading || loadingError) return <div className="account-creating-wrapper"><div className="create-acc-section"><Loader/></div></div>
  if (successSignInToken === false) return <div className="account-creating-wrapper"><div className="create-acc-section"><p className="title24-semibold-outfit error-message">Registration token is not active, check the registration link for correctness</p></div></div>


  return (
    <div className="account-creating-wrapper">
      <CreateAcc token={tokenHash} />
    </div>
  )
}

export default AccountCreating

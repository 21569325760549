import React, { useEffect } from "react";
import "./registerPayment.scss";
import { DefaultInput, CardNumberElementInput, CardYearElementInput, CardMonthElementInput, CardCvvElementInput } from "../../../../shared";


const RegisterPayment = ({ handleSubmit, changeHandlerData, formRef, formData, isError, isMissingAt, isMissingDot, setIsValidYear, setIsValidMonth, setIsValidCVV, setIsValidNumber }) => {

  useEffect(() => {
    const checkClassInterval = setInterval(() => {
      const element = document.querySelector('.recurly-element.recurly-element-number');
      if (element) {
        const isValid = element.classList.contains('recurly-element-number-valid');
        setIsValidNumber(isValid);
      }
    }, 500); 
    return () => clearInterval(checkClassInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    const checkClassInterval = setInterval(() => {
      const element = document.querySelector('.recurly-element.recurly-element-cvv');
      if (element) {
        const isValid = element.classList.contains('recurly-element-cvv-valid');
        setIsValidCVV(isValid);
      }
    }, 500);
  
    return () => clearInterval(checkClassInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkClassInterval = setInterval(() => {
      const element = document.querySelector('.recurly-element.recurly-element-month');
      if (element) {
        const isValid = element.classList.contains('recurly-element-month-valid');
        setIsValidMonth(isValid);
      }
    }, 500);
  
    return () => clearInterval(checkClassInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkClassInterval = setInterval(() => {
      const element = document.querySelector('.recurly-element.recurly-element-year');
      if (element) {
        const isValid = element.classList.contains('recurly-element-year-valid');
        setIsValidYear(isValid);
      }
    }, 500);
  
    return () => clearInterval(checkClassInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="register-payment-form-block">
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className="form-element">
          <div className="form-wrapper">
            <DefaultInput
              text={"First name"}
              placeholder={"Enter your first name"}
              value={formData.firstName}
              onChange={changeHandlerData}
              dataRecurly="first_name"
              name="firstName"
            />


            <DefaultInput
              text={"Last name"}
              placeholder={"Enter your last name"}
              value={formData.lastName}
              onChange={changeHandlerData}
              dataRecurly="last_name"
              name="lastName"
            />

            <DefaultInput
              text={"Email"}
              placeholder={"Enter your email"}
              dataRecurly="email"
              name="email"
              value={formData.email}
              onChange={changeHandlerData}
              isError={formData.email.length > 0 && isError}
            />
            {formData.email.length > 0 && isError && (
              <p className="title11-regular-outfit error-message null-padding">
                {isMissingAt
                  ? "Email must contain '@' symbol."
                  : isMissingDot
                    ? "Email must contain '.' after '@'."
                    : "Please enter a valid email address."}
              </p>
            )}
          </div>
        </div>

        <div className="form-element">
          <p className="title18-semibold-outfit text-element">Credit card</p>
          <div className="form-wrapper">
            <CardNumberElementInput
              text={"Card number"}

            />
            <div className="form-flex">
              <div className="flex">
                <CardMonthElementInput
                  placeholder={"MM"}
                  text={"Expires on"}
                />
                <CardYearElementInput
                  placeholder={"YY"}
                  text={"_"}
                />
              </div>

              <CardCvvElementInput
                placeholder={"YY"}
                text={"CVC"}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterPayment;